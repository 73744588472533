import { mapActions, mapGetters } from 'vuex';

//---helpers
import { BLOG_VIDEOS_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import VideoCard from '@/components/cards/VideosCard/index.vue';
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import Pagination from '@/components/@general/Pagination/index.vue';

export default {
  name: 'Videos',
  mixins: [dynamicHead],
  components: {
    VideoCard,
    BreadcrumbsSimple,
    Pagination
  },
  props: {},
  data() {
    return {
      videosType: BLOG_VIDEOS_TYPE.MASTER_CLASS,
      videosList: [],
      videosListMeta: null,
      isVideosListLoading: false
    };
  },
  created() {
    let { name } = this.$route;
    if (name.indexOf(BLOG_VIDEOS_TYPE.VIDEOS) > -1) {
      this.videosType = BLOG_VIDEOS_TYPE.VIDEOS;
    }
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    }),
    pageTitle() {
      return this.videosType === BLOG_VIDEOS_TYPE.VIDEOS
        ? this.$t('page.video.title')
        : this.$t('page.masterClass.title');
    },
    paginationRootPath() {
      return this.videosType === BLOG_VIDEOS_TYPE.VIDEOS ? 'video-materialy' : 'video-master-klassy';
    }
  },
  methods: {
    ...mapActions({
      getBlogVideos: 'blog/GET_BLOG_VIDEOS',
      getBlogMasterClasses: 'blog/GET_BLOG_MASTER_CLASSES',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _getVideosList() {
      this.isVideosListLoading = true;
      let { page } = this.$route.params;
      let result = null;
      if (this.videosType === BLOG_VIDEOS_TYPE.VIDEOS) {
        result = await this.getBlogVideos({ page: page ? Number(page) : 1 });
      } else if (this.videosType === BLOG_VIDEOS_TYPE.MASTER_CLASS) {
        result = await this.getBlogMasterClasses({ page: page ? Number(page) : 1 });
      }

      this.videosList = result?.data;
      this.videosListMeta = result?.meta;
      this.isVideosListLoading = false;
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      promiseCollection.push(this._getVideosList());
      promiseCollection.push(
        this.getMetaData(this.paginationRootPath).then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
